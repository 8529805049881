import * as React from "react"
import Seo from "../../components/seo"
import "../../styles/styles.sass"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import HireBanner from "../../views/hire/hireBanner"

const TogetherPage = () => {
  return (
  <main className="mercuryView projectView projectTogether">
    <Seo
      title="Philip Amour - Together"
      description="Read my case study on Together to learn about my role and approach to product design."
      image="/imageSEOTogether.jpg"
    />

  <Header headerType="detail" />

  <div className="mercuryContent">

    <section className="ProjectDetail ProjectTogether ProjectIntro">
      <div className="heroImage"></div>
      <div className="details">
        <div className="largeTitle">Together</div>
        <div className="title">Together</div>
        <div className="description">Deeply connect with others around topics that matter.</div>
      </div>
    </section>

    <section className="ProjectDetail ProjectTogether DetailSection SectionGroups">
      <div className="ChapterSummary">
        <div className="summary">
          <div className="title">Group Relationships</div> 
          <div className="body">Utilize rich media messaging to engage with other members of the group</div>
        </div>
        <div className="summaryImage"></div>
      </div>

      <div className="ChapterSection ChapterGroups">
        <div className="chapterImages">
          <div className="chapterImage chapterImage1">
            <StaticImage
              src="../../images/together/Groups1.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Groups mockup."
            />
          </div>

          <div className="chapterImage chapterImage2">
            <StaticImage
              src="../../images/together/Groups2.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Groups mockup."
            />
          </div>

          <div className="chapterImage chapterImage3">
            <StaticImage
              src="../../images/together/Groups3.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Groups mockup."
            />
          </div>
        </div>
      </div>
    </section>

    <section className="ProjectDetail ProjectTogether DetailSection SectionLearning">
      <div className="ChapterSummary">
        <div className="summary">
          <div className="title">Collaborative Learning</div> 
          <div className="body">Grow together by deep diving into custom guided study programs.</div>
        </div>
        <div className="summaryImage"></div>
      </div>

      <div className="ChapterSection ChapterLearning">
        <div className="chapterImages">
          <div className="chapterImage chapterImage1">
            <StaticImage
              src="../../images/together/Learning1.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Learning mockup."
            />
          </div>

          <div className="chapterImage chapterImage2">
            <StaticImage
              src="../../images/together/Learning2.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Learning mockup."
            />
          </div>

          <div className="chapterImage chapterImage3">
            <StaticImage
              src="../../images/together/Learning3.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Learning mockup."
            />
          </div>
        </div>
      </div>
    </section>

    <section className="ProjectDetail ProjectTogether DetailSection SectionEventst">
      <div className="ChapterSummary">
        <div className="summary">
          <div className="title">Intentional Encounters</div> 
          <div className="body">Coordinate activities together as a group both physically and virtually.</div>
        </div>
        <div className="summaryImage"></div>
      </div>

      <div className="ChapterSection ChapterEvents">

        <div className="chapterImages">
          <div className="chapterImage chapterImage1">
            <StaticImage
              src="../../images/together/Events1.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Events mockup."
            />
          </div>

          <div className="chapterImage chapterImage2">
            <StaticImage
              src="../../images/together/Events2.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Events mockup."
            />
          </div>

          <div className="chapterImage chapterImage3">
            <StaticImage
              src="../../images/together/Events3.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Events mockup."
            />
          </div>
        </div>
      </div>
    </section>

    <section className="ProjectDetail ProjectTogether DetailSection SectionInteractions">
      <div className="ChapterSummary">
        <div className="summary">
          <div className="title">Personalized Interactions</div> 
          <div className="body">Group leaders benefit from assistive interaction prompts and workflows.</div>
        </div>
        <div className="summaryImage"></div>
      </div>

      <div className="ChapterSection ChapterInteractions">
        <div className="chapterImages">
          <div className="chapterImage chapterImage1">
            <StaticImage
              src="../../images/together/Interactions1.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Interactions mockup."
            />
          </div>

          <div className="chapterImage chapterImage2">
            <StaticImage
              src="../../images/together/Interactions2.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Interactions mockup."
            />
          </div>

          <div className="chapterImage chapterImage3">
            <StaticImage
              src="../../images/together/Interactions3.png"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              alt="Interactions mockup."
            />
          </div>
        </div>
      </div>
    </section>

    <HireBanner
      bannerTitle=""
      bannerSubtitle="Love the work I did for Together? Let&rsquo;s work together on your app!"
    />
  </div>

  <Footer />

  </main>
  )
}

export default TogetherPage
